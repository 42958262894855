import * as S from './styles';

export default function UserLocation({ top, left }) {
  if (!(top && left)) {
    return <div />
  }

  return (
    <S.UserLocation
      top={top}
      left={left}
    >
      <img src="/images/user_point_without_direction.png" />
    </S.UserLocation>
  )
}
