import * as S from './styles';

export default function LocationNode({ marker }) {
  if (!marker) {
    return <div />
  }

  return (
    <S.LocationNode
      top={marker.top}
      left={marker.left}
    >
      <img src="/images/pin_red.png" />
    </S.LocationNode>
  )
}
