import { useEffect, useState } from 'react';

export default function Map({ eventId, onMapInit, mapRef }) {
  const [map, setMap] = useState<string | null>(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/map/${eventId}`)
      .then(result => result.json())
      .then(result => setMap(result.image));
  }, [eventId]);

  if (!map) {
    return <div />
  }

  return (
    <img
      ref={mapRef}
      id="map"
      className="map"
      src={map}
      onLoad={() => {
        onMapInit();
      }}
    />
  )
}
