import styled from 'styled-components';

export const LocationNode: any = styled.div`
  position: absolute;
  height: 15px;
  width: 15px;
  top: ${(props: any) => `calc(${props.top}px - 7.5px)`};
  left: ${(props: any) => `calc(${props.left}px - 7.5px)`};
  display: flex;
`
