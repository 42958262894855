import * as S from './styles';

export default function SearchPath({ searchSvg }) {
  if (!searchSvg) {
    return <div />
  }

  return (
    <S.SearchPath>
      <img src={`data:image/svg+xml;utf8,${searchSvg}`} />
    </S.SearchPath>
  )
}
