import styled from 'styled-components';

export const Home = styled.div`
  height: 100vh;
  width: 100vw;

  > main {
    display: flex;
    background: #F7F7F7;
    align-items: center;
    position: relative;
    padding: 50px 0 50px 0;
  }
`

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: columns;
  align-items: center;
  padding: 1rem 5rem;
  background: linear-gradient(45deg,#fbcb11,#f80);
  height: 50px;

  img {
    height: 32px;
    width: auto;
  }
`

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: columns;
  align-items: center;
  padding: 1rem 5rem;
  background: linear-gradient(45deg,#fbcb11,#f80);
  z-index: 2;
  height: 50px;
`

export const MapWrapper = styled.div`
  flex: 1;
  display: flex;
`

export const MapContainer = styled.div`
  position: relative;
  margin: 0 auto;

  .map {
    max-height: 100vh;
    max-width: 100vh;
    object-fit: contain;
  }
`

export const SearchIcon = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: none;
  color: #999;
  border: 1px solid #CCC;
  border-radius: 3px;
  font-size: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;

  @media screen and (max-width: 599px) {
    display: flex;
  }
`

export const PointOfInterest: any = styled.div`
  position: absolute;
  top: ${(props: any) => props.y}px;
  left: ${(props: any) => props.x}px;
  height: 24px;
  width: 24px;
  background: url(${(props: any) => props.image});
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
`
