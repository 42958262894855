import { useReducer, useEffect, useMemo } from 'react';
import {
  values,
  isEmpty,
} from 'ramda';
import debounce from 'lodash.debounce';

import myConfig from 'config/my_config.json';

const INITIAL_FLOOR = 251;

export default function useDolphin() {
  const initialState: any = {
    configData: {},
    nodes: {},
    mapConfig: {},
    eventConnections: {},
    currentFloor: INITIAL_FLOOR,
    status: 'INITIALIZING',
    userNode: {},
    searchPath: {},
    renderedWidth: 0,
    renderedHeight: 0,
    renderedPadding: 0,
  };
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
  );

  function reducer(state, action) {
    switch (action.type) {
      case 'LOAD_CONFIG':
        return {
          ...state,
          configData: action.payload.configData,
          status: 'CONFIG_LOADED',
        }
      case 'LOAD_MAP_CONFIG':
        return {
          ...state,
          mapConfig: action.payload.mapConfig,
          status: 'MAP_CONFIG_LOADED',
        }
      case 'INIT_MAP_SIZE':
        return {
          ...state,
          renderedWidth: action.payload.renderedWidth,
          renderedHeight: action.payload.renderedHeight,
          renderedPadding: action.payload.renderedPadding,
          status: 'MAP_SIZE_UPDATED',
        }
      case 'INIT_NODES':
        return {
          ...state,
          nodes: action.payload.nodes,
          eventConnections: action.payload.eventConnections,
          status: 'NODES_INIT_COMPLETED',
        }
      case 'INIT_NODE_CONNECTIONS':
        return {
          ...state,
          nodes: action.payload.nodes,
          status: 'NODE_CONNECTION_INIT_COMPLETED',
        }
      case 'UPDATE_USER_NODE':
        return {
          ...state,
          userNode: action.payload.userNode,
          marker: action.payload.marker ?? state.marker,
          status: 'USER_NODE_UPDATED',
        }
      case 'UPDATE_MARKER':
        return {
          ...state,
          marker: action.payload.marker,
          status: 'MARKER_UPDATED',
        }
      case 'UPDATE_SEARCH_PATH':
        return {
          ...state,
          searchPath: action.payload.searchPath,
          status: 'SEARCH_PATH_UPDATED',
        }
      case 'UPDATE_SEARCH_SVG':
        return {
          ...state,
          searchSvg: action.payload.searchSvg,
          status: 'SEARCH_SVG_UPDATED',
        }
      case 'REFRESH_USER_NODE':
        return {
          ...state,
          status: 'USER_NODE_REFRESH',
        }
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    switch (state.status) {
      case 'INITIALIZING':
        console.log('INITIALIZING')
        readLocalConfig();
        break;
      case 'CONFIG_LOADED':
        console.log('CONFIG_LOADED')
        initMaps();
        break;
      case 'MAP_CONFIG_LOADED':
        console.log('MAP_CONFIG_LOADED')
        initNodes();
        break;
      case 'NODES_INIT_COMPLETED':
        console.log('NODES_INIT_COMPLETED')
        initNodeConnection();
        break;
      case 'NODE_CONNECTION_INIT_COMPLETED':
        initMapSize();
        console.log('NODE_CONNECTION_INIT_COMPLETED')
        break;
      case 'USER_NODE_UPDATED':
        console.log('USER_NODE_UPDATED');
        if (state.marker) {
          handleSearch(state.marker);
        }
        break;
      case 'MARKER_UPDATED':
        console.log('MARKER_UPDATED')
        handleSearch(state.marker);
        break;
      case 'SEARCH_PATH_UPDATED':
        console.log('SEARCH_PATH_UPDATED');
        updateSearchPath();
        break;
      case 'SEARCH_SVG_UPDATED':
        console.log('SEARCH_SVG_UPDATED');
        break;
      case 'MAP_SIZE_UPDATED':
        console.log('MAP_SIZE_UPDATED');
        break;
      case 'USER_NODE_REFRESH':
        console.log('USER_NODE_REFRESH')
        refreshUserNode();
        break;
      default:
        throw new Error(`Unknown status: ${state.status}`);
    }
  }, [state.status]);

  const handleResize = () => {
    initMapSize();

    dispatch({
      type: 'REFRESH_USER_NODE',
    });
  };

  const debouncedHandleResize = useMemo(
    () => debounce(handleResize, 10),
    [],
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('reisze', debouncedHandleResize);
    }
  }, [state.userNode]);


  async function readLocalConfig() {
    try {
      const configData = myConfig;

      dispatch({
        type: 'LOAD_CONFIG',
        payload: {
          configData,
        }
      })
    } catch(e) {
      console.error(e);
    }
  }

  async function initMaps() {
    const mapConfig = {};
    const { configData } = state;

    for (const event of configData['events']) {
      mapConfig[event['info']['id']] = event['info'];
    }

    dispatch({
      type: 'LOAD_MAP_CONFIG',
      payload: {
        mapConfig,
      }
    })
  }

  async function initMapSize() {
    const mapContainer: any = document.querySelector('#map-container');
    const style: any = getComputedStyle(mapContainer);
    const renderedWidth = mapContainer.clientWidth;
    const renderedHeight = mapContainer.clientHeight;

    dispatch({
      type: 'INIT_MAP_SIZE',
      payload: {
        renderedWidth,
        renderedHeight,
        renderedPadding: parseFloat(style.marginLeft),
      }
    })
  }

  async function initNodes() {
    const { configData } = state;
    const events = configData.navigation;
    const nodes = {};
    const eventConnections = {};

    for (const event of events) {
      const eventId = event.event_id;
      nodes[eventId] = {};

      for (const node of event.nodes) {
        const nodeHash = getNodeHashValue(node.x, node.y);
        nodes[eventId][nodeHash] = setupNode(eventId, node);
      }

      eventConnections[eventId] = [];

      for (const connection in event.event_connections) {
        eventConnections[eventId].push({
          'node_id': connection['node_id'],
          'connected_node_id': connection['connected_node_id'],
          'type': connection['type'],
        });
      }
    }

    dispatch({
      type: 'INIT_NODES',
      payload: {
        nodes,
        eventConnections,
      }
    });
  }

  async function initNodeConnection() {
    const { nodes: initialNodes, configData } = state;
    const events = configData['navigation'];
    let nodes = initialNodes;

    for (const event of events) {
      const eventId = event['event_id'];

      for (const connection of event['connections']) {
        const startNode = connection['node_id'];
        const endNode = connection['connected_node_id'];

        const startCoor = getNodeById(startNode);
        const endCoor = getNodeById(endNode);

        const startNodeHash = getNodeHashValue(startCoor['x'], startCoor['y']);
        const endNodeHash = getNodeHashValue(endCoor['x'], endCoor['y']);

        const startNodeUpdated = getNodeByHash(startNodeHash);
        const endNodeUpdated = getNodeByHash(endNodeHash);

        nodes = connectNodes(startNodeUpdated, endNodeUpdated, eventId, nodes);
      }
    }

    dispatch({
      type: 'INIT_NODE_CONNECTIONS',
      payload: {
        nodes,
      }
    });
  }

  function getNodeHashValue(x, y): string {
    return `${Math.round(x)}-${Math.round(y)}`;
  }

  function getNodeById(nodeId: number)  {
    const { nodes } = state;
    let node;

    for (const eventNodes of Object.values(nodes)) {
      // print('Finding nodeId: $nodeId in ${floorNodes.key}');
      node = Object.values(eventNodes as any).find((node: any) => {
        return node.id == nodeId;
      });

      if (node != null) {
        return node;
      }
    }

    console.error(`NodeId: ${nodeId} not found`);
    throw Error();
  }

  function getNodeByHash(nodeHash: string) {
    const { nodes } = state;

    let node;

    for (let floorNodes of values(nodes)) {
      node = floorNodes[nodeHash];

      if (node != null) {
        return node;
      }
    }

    console.error(`NodeHash: ${nodeHash} not found`);
    throw Error();
  }

  function setupNode(eventId: number, node) {
    return {
      ...node,
      'top': getY(node.y, eventId) - 2.5 / 2,
      'left': getX(node.x, eventId) - 2.5 / 2,
      'adjacencies': [],
    };
  }

  function getX(x: number, eventId: number): number {
    try {
      const { mapConfig } = state;
      const mapScale: number = getMapScale(eventId);
      const mapX1: number = mapConfig[eventId].map_x1;

      return (x - mapX1) * mapScale;
    } catch(e) {
      console.error(e);
      return x;
    }
  }

  function getY(y: number, eventId: number): number {
    try {
      const { mapConfig } = state;
      const mapScale: number = getMapScale(eventId);
      const mapY1: number = mapConfig[eventId].map_y1;
      const mapY2: number = mapConfig[eventId].map_y2;

      return (Math.abs(mapY2 - mapY1) - y + mapY1) * mapScale;
    } catch(e) {
      console.error(e);
      return y;
    }
  }

  function parseX(x: number, eventId: number): number {
    try {
      const { mapConfig } = state;
      const mapScale: number = getMapScale(eventId);
      const mapX1: number = mapConfig[eventId].map_x1;

      return x / mapScale + mapX1;
    } catch(e) {
      console.error(e);
      return x;
    }
  }

  function parseY(y: number, eventId: number): number {
    try {
      const { mapConfig } = state;
      const mapScale: number = getMapScale(eventId);
      const mapY1: number = mapConfig[eventId].map_y1;
      const mapY2: number = mapConfig[eventId].map_y2;

      return (y / mapScale - mapY1 - Math.abs(mapY2 - mapY1)) * -1;

    } catch(e) {
      console.error(e);
      return y;
    }
  }

  function getMapScale(eventId: number): number {
    try {
      const { mapConfig, renderedWidth } = state;

      const config = mapConfig[eventId];
      const mapX1: number = config.map_x1;
      const mapX2: number = config.map_x2;

      return renderedWidth / Math.abs(mapX2 - mapX1);
    } catch(e) {
      console.error(e);
      return 1;
    }
  }

  function getLowestFscoreFromQueue(queue: any[]) {
    queue.sort((a, b) => {
      if (a.f_scores > b.f_scores) {
        return 1;
      } else if (a.f_scores < b.f_scores) {
        return -1;
      } else {
        return 0;
      }
    });

    return queue[0];
  }

  function connectNodes(startNode, endNode, eventId, initialNodes) {
    const { currentFloor } = state;
    const nodes = initialNodes;
    const currentEventId: number = eventId ?? currentFloor;
    let headHashValue: string;

    const distanceX: number = endNode['x'] - startNode['x'];
    const distanceY: number = endNode['y'] - startNode['y'];

    const part: number = Math.ceil((Math.abs(distanceX) + Math.abs(distanceY)) / 5);

    for (let i = 0; i < part; i++) {
      const drawX: number = startNode['x'] + distanceX / part;
      const drawY: number = startNode['y'] + distanceY / part;
      const tailHashValue: string = getNodeHashValue(drawX, drawY);

      if (!nodes[currentEventId][tailHashValue]) {
        nodes[currentEventId][tailHashValue] = {
          'x': drawX,
          'y': drawY,
          'top': getY(drawY, currentEventId),
          'left': getX(drawX, currentEventId),
          'adjacencies': [],
        };
      }

      const tailNode = nodes[currentEventId][tailHashValue];
      const drawNode = {
        'x': drawX,
        'y': drawY,
      };

      const cost = getCost(startNode, drawNode);

      headHashValue = getNodeHashValue(startNode['x'], startNode['y']);

      nodes[currentEventId][headHashValue]['adjacencies'].push({
        'node': {
          'x': drawX,
          'y': drawY,
        },
        'cost': cost,
      });

      startNode = tailNode;
      nodes[currentEventId][tailHashValue] = startNode;
    }

    headHashValue = getNodeHashValue(startNode['x'], startNode['y']);

    const cost = getCost(startNode, endNode);

    nodes[currentEventId][headHashValue]['adjacencies'].push({
      'node': {
        ...endNode,
        'adjacencies': [],
      },
      'cost': cost,
    });

    return nodes;
  }

  function getCost(node1, node2): number {
    return Math.sqrt(
      Math.pow(node1['x'] - node2['x'], 2) + Math.pow(node1['y'] - node2['y'], 2)
    );
  }

  function setUserNode(x, y, eventId) {
    const { renderedPadding } = state;

    console.log(parseX(x, eventId), parseY(y, eventId));

    dispatch({
      type: 'UPDATE_USER_NODE',
      payload: {
        userNode: {
          x: parseX(x, eventId),
          y: parseY(y, eventId),
          top: y,
          left: x,
          eventId,
          rawX: x,
          rawY: y,
        }
      }
    })
  }

  function refreshUserNode() {
    const { userNode, marker } = state;

    dispatch({
      type: 'UPDATE_USER_NODE',
      payload: {
        userNode: {
          ...userNode,
          top: getY(userNode.y, userNode.eventId),
          left: getX(userNode.x, userNode.eventId),
        },
        marker: !!marker
          ? {
              ...marker,
              top: getY(marker.y, marker.event_id),
              left: getX(marker.x, marker.event_id),
            }
          : null,
      }
    })
  }

  function getNearNodes(targetX: number, targetY: number, floor: number) {
    const { nodes } = state;
    const x: number = Math.floor(targetX);
    const y: number = Math.floor(targetY);
    let layer = 0;
    const list: any = [];

    while (isEmpty(list) && layer < 500) {
      for (let i = -layer; i <= layer; i += 1) {
        for (let j = -layer; j <= layer; j += 1) {
          const hashValue = getNodeHashValue(x + i, y + j);;
          const node = nodes[floor][hashValue];

          if (node != null && !(node.x === targetX && node.y === targetY)) {
            list.push(node);
          }
        }
      }
      layer += 1;
    }

    // if (list.isEmpty) {
    //   print('Cant find near nodes: targetX ($targetX) targetY ($targetY) floor ($floor)');
    // } else {
    //   print('getNearNodes: ${list.length} found ${list[0]}');
    // }

    return list;
  }

  function handleSearch(pointOfInterest) {
    const {
      userNode,
      nodes,
      eventConnections,
    } = state;
    const searchPath = {};
    const currentBeaconFloor = userNode['eventId'];
    let connectedNodes = nodes;

    if (pointOfInterest['x'] == null || pointOfInterest['y'] == null) {
      return;
    }

    const x = pointOfInterest['x'];
    const y = pointOfInterest['y'];
    const eventId = pointOfInterest['event_id'];

    const selectedPointOfInterest = pointOfInterest;
    let searchPathConnectionType;

    // if (selectedPointOfInterest['x'] != pointOfInterest['x'] && selectedPointOfInterest['y'] != pointOfInterest['y']) {
    //   shouldLogArrival = true;
    // }

    const marker = {
      'x': x,
      'y': y,
      'top': getY(y, eventId),
      'left': getX(x, eventId),
      'eventId': eventId,
    };

    if (userNode['x'] == null || userNode['y'] == null) {
      return;
    }

    const pointerNearNodes = getNearNodes(userNode['x'], userNode['y'], currentBeaconFloor);
    const boothNearNodes = getNearNodes(x, y, eventId);
    const userNodeHash = getNodeHashValue(userNode['x'], userNode['y']);

    userNode['adjacencies'] = [];

    connectedNodes[currentBeaconFloor][userNodeHash] = userNode;

    for (const node of pointerNearNodes) {
      const nodeHash = getNodeHashValue(node['x'], node['y']);
      const searchNode = connectedNodes[currentBeaconFloor][nodeHash];

      // userNode = nodes[eventId][userNodeHash] ?? userNode;

      // print('userNode $userNode');
      // print('searchNode $searchNode');
      connectedNodes = connectNodes(userNode, searchNode, userNode['eventId'], connectedNodes);
      connectedNodes = connectNodes(searchNode, userNode, userNode['eventId'], connectedNodes);
    };

    var boothNearNode = boothNearNodes[0];
    var boothNearNodeHashValue = getNodeHashValue(boothNearNode['x'], boothNearNode['y']);

    var boothNode = connectedNodes[eventId][boothNearNodeHashValue];

    if (currentBeaconFloor === marker['eventId']) {
      searchPathConnectionType = null;
      astarSearch(connectedNodes, userNode, boothNode, eventId);
    } else {
      // var levelRoutes = searchLevel(userNode['eventId'], boothNode['eventId'], []);
      var levelRoutes = [userNode['eventId']];
      var pathRoutes: any = [];

      var startNode = userNode;

      for (var level in levelRoutes) {
        // List searchRoutes = [];
        let connectionCost: any = [];

        for (const connection of eventConnections[level]) {
          var targetNode = getNodeById(connection['node_id']);
          var cost = getCost(startNode, targetNode);

          connectionCost.push({
            cost: cost,
            node: targetNode,
            type: connection['type'],
            connectedNodeId: connection['connected_node_id'],
          });
        }

        connectionCost.sort((a, b) => {
          return Math.round(a['cost']) - Math.round(b['cost']);
        });

        searchPathConnectionType = connectionCost[0]['type'];
        // print('searchPathConnectionType: $searchPathConnectionType');
        var nearestConnectionNode = connectionCost[0]['node'];
        // print('connectionCost: ${connectionCost[0]['connectedNodeId']}');

        let path: any = astarSearch(connectedNodes, startNode, nearestConnectionNode, level, true);

        pathRoutes.push(path);

        // print(getNodeById(connectionCost[0]['connectedNodeId']));

        var nextFloorConnectionNode = getNodeById(connectionCost[0]['connectedNodeId']);
        // print('nextFloorConnectionNode: $nextFloorConnectionNode');

        path = astarSearch(connectedNodes, nextFloorConnectionNode, boothNode, eventId, true);

        pathRoutes.push(path);
      }


      searchPath[userNode['eventId']] = pathRoutes[0];
      searchPath[eventId] = pathRoutes[1];

      // print(startNode);
      // print(pathRoutes);
    }
  }

  function astarSearch(nodes, userNode, boothNode, eventId, reset = false, result = false) {
    const explored = {};
    const path: any = [];
    const searchPath = {};

    const userNodeHashValue = getNodeHashValue(userNode['x'], userNode['y']);
    const boothNodeHashValue = getNodeHashValue(
      boothNode['x'],
      boothNode['y'],
    );
    const cacheHashValue = userNodeHashValue + boothNodeHashValue;

    // if (routeCache.containsKey(cacheHashValue)) {
    //   print('return cached route');

    //   if (result) {
    //     return routeCache[cacheHashValue];
    //   } else {
    //     searchPath.update(eventId, (value) => reset ? [] : routeCache[cacheHashValue]);
    //     return;
    //   }
    // }

    let found = false;
    const queue: any = [];

    userNode['g_scores'] = 0;
    queue.push(userNode);

    while (!found && !isEmpty(queue)) {
      const currentNode = getLowestFscoreFromQueue(queue);
      const nodeHash = getNodeHashValue(currentNode['x'], currentNode['y']);

      const queueIndex = queue.findIndex((item) => {
        var itemHash = getNodeHashValue(item['x'], item['y']);
        return itemHash === nodeHash;
      });

      if (queueIndex !== -1) {
        queue.splice(queueIndex, 1);
      }

      explored[nodeHash] = 1;

      if (nodeHash === boothNodeHashValue) {
        console.log('found');
        found = true;
      } else {
        for (const adjacency of currentNode['adjacencies']) {
          const childHash = getNodeHashValue(adjacency['node']['x'], adjacency['node']['y']);
          const child = nodes[eventId][childHash];
          const cost = adjacency['cost'];
          const gScoresTmp = currentNode['g_scores'] + cost;
          const fScoresTmp = gScoresTmp + child['x'] + child['y'];

          const index = queue.findIndex((node) => {
            const itemHash = getNodeHashValue(node['x'], node['y']);
            return itemHash === childHash;
          });

          var childFScore = child['f_scores'] ? child['f_scores'] : 0;

          if (!!explored[childHash] && fScoresTmp >= childFScore) {
            continue;
          } else if (index === -1 || fScoresTmp < childFScore) {
            child['parent'] = {
              // ...currentNode,
              x: currentNode['x'],
              y: currentNode['y'],
              parent: null,
              adjacencies: null,
            };
            child['g_scores'] = gScoresTmp;
            child['f_scores'] = fScoresTmp;

            if (index !== -1) {
              queue.splice(index, 1);
            }

            queue.push(child);
          }
        };
      }
    }

    let node = boothNode;

    while (node != null) {
      path.push({
        x: node['x'],
        y: node['y'],
      });

      if (!node['parent'] || (node['x'] === userNode['x'] && node['y'] === userNode['y'])) {
        break;
      }

      const parentNode = node['parent'];
      const parentNodeHash = getNodeHashValue(parentNode['x'], parentNode['y']);

      node = nodes[eventId][parentNodeHash];
    }

    // routeCache[cacheHashValue] = List.from(path);

    if (result) {
      return path;
    }

    searchPath[eventId] = reset ? [] : path;

    dispatch({
      type: 'UPDATE_SEARCH_PATH',
      payload: {
        searchPath,
      }
    })
  }

  function handlePointOfInterestSelect(pointOfInterest) {
    dispatch({
      type: 'UPDATE_MARKER',
      payload: {
        marker: {
          ...pointOfInterest,
          top: getY(pointOfInterest.y, pointOfInterest.event_id),
          left: getX(pointOfInterest.x, pointOfInterest.event_id),
        }
      }
    })
  }

  function updateSearchPath() {
    const {
      searchPath,
      marker,
      userNode,
      renderedWidth,
    } = state;
    const { eventId } = userNode;
    const path = searchPath[251];

    let svg = `<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${renderedWidth} ${renderedWidth}">`;

    var triangleSize = 3;

    for (var i = 1; i < path.length - 2; i = i + 2) {
      var x1 = getX(path[i]['x'], eventId);
      var x2 = getX(path[i + 2]['x'], eventId);
      var y1 = getY(path[i]['y'], eventId);
      var y2 = getY(path[i + 2]['y'], eventId);

      var width = x2 - x1;
      var height = y2 - y1;

      if (i === 1) {
        width = x1 - marker['left'];
        height = y1 - marker['top'];
        x2 = marker['left'];
        y2 = marker['top'];
      }

      const point1 = `${x1 - triangleSize}, ${y1 + triangleSize}`;
      const point2 = `${x1 + triangleSize}, ${y1 + triangleSize}`;
      const point3 = `${x1}, ${y1 - triangleSize * 2.75}`;

      const angleToNextPoint = - Math.atan2(width, height) * 180 / Math.PI;

      svg += `<polygon points="${point1} ${point2} ${point3}" fill="rgba(0, 169, 234, 1)" transform="rotate(${angleToNextPoint} ${x1} ${y1})" />`;
    }

    svg += '</svg>';

    dispatch({
      type: 'UPDATE_SEARCH_SVG',
      payload: {
        searchSvg: svg,
      }
    })
  }

  return {
    ...state,
    setUserNode,
    handlePointOfInterestSelect,
    initMapSize,
    getX,
    getY,
  };
}
