import styled from 'styled-components';

export const SearchPanel: any = styled.div`
  width: 250px;
  min-width: 250px;
  height: 100vh;
  max-height: 100vh;
  background: #FFF;
  border-left: 1px solid #CCC;
  overflow-y: auto;
  z-index: 2;

  @media screen and (max-width: 599px) {
    display: ${(props: any) => props.display};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
  }

  header {
    position: sticky;
    top: 0;
  }
`

export const PointOfInterests = styled.div`

`

export const PointOfInterest = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #CCC;
  transition: 500ms;
  cursor: pointer;

  &:hover {
    background: #F7F7F7;
  }

  .address {
    font-size: 75%;
  }
`
