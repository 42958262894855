import { useEffect, useState } from 'react';
import { Input } from 'antd';

import * as S from './styles';

const { Search } = Input;

export default function SearchPanel({ onPointOfInterestSelect, setShowSearchPanelOnMobile, showSearchPanelOnMobile = false }) {
  const [pointOfInterests, setPointOfInterests] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/point-of-interests/251`)
      .then(result => result.json())
      .then((result) => setPointOfInterests(result));
  }, []);

  function filter(pointOfInterests): any[] {
    if (!query) {
      return pointOfInterests;
    }

    return pointOfInterests.filter((pointOfInterests: any) =>
      pointOfInterests.c_name.includes(query) ||
      pointOfInterests.e_name.includes(query) ||
      pointOfInterests.sc_name.includes(query)
    );
  }

  return (
    <S.SearchPanel
      display={showSearchPanelOnMobile ? 'block' : 'none'}
    >
      <header>
        <Search
          onSearch={(value) => {
            setQuery(value);
          }}
          allowClear
        />
      </header>
      <main>
        <S.PointOfInterests>
          {
            filter(pointOfInterests).map((pointOfInterest: any) => (
              <S.PointOfInterest
                key={pointOfInterest.id}
                onClick={() => {
                  onPointOfInterestSelect(pointOfInterest);
                  setShowSearchPanelOnMobile(false);
                }}
              >
                <div className="name">{ pointOfInterest.c_name }</div>
                <div className="address">{ pointOfInterest.c_address }</div>
              </S.PointOfInterest>
            ))
          }
        </S.PointOfInterests>
      </main>
    </S.SearchPanel>
  )
}
