import { useState, useRef } from 'react';
import {
  SearchOutlined,
} from '@ant-design/icons';

import * as S from './styles';

import useDolphin from 'hooks/useDolphin';

import Nodes from './components/Nodes';
import Map from './components/Map';
import UserLocation from './components/UserLocation';
import SearchPanel from './components/SearchPanel';
import SearchPath from './components/SearchPath';
import LocationMarker from './components/LocationMarker';

export default function Home() {
  const dolphin = useDolphin();
  const [showSearchPanelOnMobile, setShowSearchPanelOnMobile] = useState(false);
  const mapRef: any = useRef();

  return (
    <S.Home>
      <S.Header>
        <img src="/images/mostown_icon.png" />
      </S.Header>
      <main>
        <S.MapWrapper>
          <S.MapContainer
            id="map-container"
            onClick={(e: any) => {
              const rect = mapRef.current.getBoundingClientRect();
              const x = e.pageX - rect.left;
              const y = e.pageY - rect.top;

              dolphin.setUserNode(x, y, 251);
            }}
          >
            <Map
              eventId={251}
              onMapInit={dolphin.initMapSize}
              mapRef={mapRef}
            />
            {/* <Nodes nodes={values(dolphin.nodes[dolphin.currentFloor])} /> */}
            <UserLocation
              top={dolphin.userNode.top}
              left={dolphin.userNode.left}
            />
            <SearchPath searchSvg={dolphin.searchSvg} />
            <LocationMarker marker={dolphin.marker} />
            <S.PointOfInterest
              x={dolphin.getX(52.48, 251)}
              y={dolphin.getY(166.47, 251)}
              image="/images/icon_mens_room.png"
            />
            <S.PointOfInterest
              x={dolphin.getX(271.629, 251)}
              y={dolphin.getY(175.319, 251)}
              image="/images/icon_mens_room.png"
            />
            <S.PointOfInterest
              x={dolphin.getX(136.679, 251)}
              y={dolphin.getY(189.544, 251)}
              image="/images/icon_automatic_teller_machine.png"
            />
          </S.MapContainer>
        </S.MapWrapper>
        <SearchPanel
          onPointOfInterestSelect={dolphin.handlePointOfInterestSelect}
          showSearchPanelOnMobile={showSearchPanelOnMobile}
          setShowSearchPanelOnMobile={setShowSearchPanelOnMobile}
        />
        <S.SearchIcon
          onClick={() => setShowSearchPanelOnMobile(true)}
        >
          <SearchOutlined />
        </S.SearchIcon>
      </main>
      <S.Footer>
        &copy; Powered by Bluepin HK
      </S.Footer>
    </S.Home>
  )
}
